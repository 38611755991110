<template>
  <v-card>
    <v-card-title>
      <span>タスク 登録</span>
    </v-card-title>
    <v-card-text class="px-4">
      <v-card-subtitle>タスク情報</v-card-subtitle>
      <section>
        <ul>
          <li class="mb-3">
            <v-text-field
              label="タスク名"
              :value="newTaskName"
              @input="onCreateName"
              dense
              outlined
              color="primary"
            ></v-text-field>
            <p v-if="newTaskNameError" class="error-text">
              {{ newTaskNameError }}
            </p>
          </li>
          <li class="mb-3">
            <v-textarea
              label="詳細"
              :value="newTaskDetail"
              @input="onCreateDetail"
              dense
              outlined
              color="primary"
            ></v-textarea>
            <!-- <p v-if="newTaskDetailError" class="error-text">{{newTaskDetailError}}</p> -->
          </li>
          <li class="mb-3">
            <v-textarea
              label="コメント"
              :value="newTaskComment"
              @input="onCreateComment"
              dense
              outlined
              color="primary"
            ></v-textarea>
          </li>
        </ul>
        <ul class="d-flex align-center">
          <li style="width: 180px">
            <v-text-field
              :value="startDate"
              @click="startDateDialog = true"
              @click:clear="onChangeStart"
              label="開始日"
              placeholder="指定なし"
              prepend-inner-icon="mdi-calendar"
              color="primary"
              readonly
              outlined
              dense
              clearable
            ></v-text-field>
            <v-dialog
              :value="startDateDialog"
              @click:outside="startDateDialog = false"
            >
              <v-date-picker
                :value="startDate"
                @change="onChangeStartDate"
                color="primary"
                locale="ja"
              ></v-date-picker>
            </v-dialog>
          </li>
          <li>~</li>
          <li class="mr-3" style="width: 180px">
            <v-text-field
              :value="endDate"
              @click="endDateDialog = true"
              @click:clear="onChangeEnd"
              label="期限日"
              placeholder="指定なし"
              prepend-inner-icon="mdi-calendar"
              color="primary"
              readonly
              outlined
              dense
              clearable
            ></v-text-field>
            <v-dialog
              :value="endDateDialog"
              @click:outside="endDateDialog = false"
            >
              <v-date-picker
                :value="endDate"
                @change="onChangeEndDate"
                color="primary"
                locale="ja"
              ></v-date-picker>
            </v-dialog>
          </li>
          <li class="mr-3" style="width: 180px">
            <v-text-field
              :value="scheduledTime"
              @input="onCreateScheduledTime"
              label="予定時間(hours)"
              placeholder="予定時間を入力"
              prepend-inner-icon="mdi-clock-time-nine-outline"
              outlined
              dense
              clearable
              color="primary"
            ></v-text-field>
          </li>
        </ul>
        <p v-if="endDateError" class="error-text">{{ endDateError }}</p>
      </section>
      <section>
        <ul class="d-flex my-3">
          <li class="mr-3" style="width: 180px">
            <v-select
              :items="this.$store.state.categories"
              :value="categoryId"
              @change="onChangeCategory"
              label="案件名"
              item-value="category_id"
              item-text="category_name"
              prepend-inner-icon="mdi-shape"
              outlined
              dense
              color="primary"
            ></v-select>
            <p v-if="categoryError" class="error-text">{{ categoryError }}</p>
          </li>
          <!-- <li class="mr-3" style="width:180px;">
            <v-select :items="formTags" :value="tagId" @change="onChangeTag" label="タグ" item-value="tag_id" item-text="tag_name" prepend-inner-icon="mdi-tag" outlined dense color="primary"></v-select>
          </li> -->
          <li class="mr-3" style="width: 180px">
            <v-select
              :items="this.$store.state.users"
              :value="requestedId"
              @change="onChangeRequested"
              label="担当者"
              item-value="id"
              item-text="name"
              prepend-inner-icon="mdi-account"
              outlined
              dense
              color="primary"
            ></v-select>
            <p v-if="requestedUserError" class="error-text">
              {{ requestedUserError }}
            </p>
          </li>
          <li class="mr-3" style="width: 180px">
            <v-select
              :items="formUserData"
              :value="requesterId"
              @change="onChangeRequester"
              label="依頼者"
              item-value="id"
              item-text="name"
              prepend-inner-icon="mdi-account"
              outlined
              dense
              color="primary"
            ></v-select>
          </li>
          <li v-if="this.requesterId === -1" class="mr-3">
            <v-text-field
              label="依頼者記入"
              :value="requesterUserName"
              @input="onCreateRequesterUser"
              dense
              outlined
              color="primary"
            ></v-text-field>
            <p v-if="requesterUserNameError" class="error-text">
              {{ requesterUserNameError }}
            </p>
          </li>
          <li class="mr-3" style="width: 180px">
            <v-select
              :items="assign"
              :value="assignBoolean"
              @change="onChangeAssign"
              label="振分"
              item-value="value"
              item-text="label"
              prepend-inner-icon="mdi-clipboard-check"
              outlined
              dense
              color="primary"
            ></v-select>
          </li>
          <li class="mr-3" style="width: 180px">
            <v-select
              :items="Reproduction"
              :value="numRepro"
              @change="onChangeRepro"
              label="複製"
              item-value="value"
              item-text="label"
              prepend-inner-icon="mdi-card-plus"
              outlined
              dense
              color="primary"
            ></v-select>
          </li>
        </ul>
        <ul>
          <li class="d-flex align-center my-4">
            <p class="mr-2">状態&emsp;：</p>
            <v-radio-group
              :value="statusId"
              @change="onChangeStatus"
              color="primary"
              :column="false"
            >
              <v-radio
                v-for="status in SELECTSTA"
                :value="status.value"
                :label="status.label"
                :key="status.value"
              ></v-radio>
            </v-radio-group>
          </li>
          <li class="d-flex align-center my-4">
            <p class="mr-2">優先度：</p>
            <v-radio-group
              :value="priorityId"
              @change="onChangePriority"
              color="primary"
              :column="false"
            >
              <v-radio
                v-for="priority in SELECTPRI"
                :value="priority.value"
                :label="priority.label"
                :key="priority.value"
              ></v-radio>
            </v-radio-group>
          </li>
        </ul>
      </section>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn :loading="isLoading" color="primary" @click="checkCreateTasks()">
        <span>登録</span>
        <v-icon>mdi-send</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import dayjs from "dayjs";
import { myAxios } from "@/plugins/axios";
import { SELECTSTA } from "@/plugins/const";
import { SELECTPRI } from "@/plugins/const";
export default {
  data() {
    return {
      SELECTSTA: SELECTSTA,
      SELECTPRI: SELECTPRI,
      statusId: 6,
      priorityId: 5,
      startDateDialog: false,
      endDateDialog: false,
      startDate: dayjs().format("YYYY-MM-DD"),
      endDate: dayjs().format("YYYY-MM-DD"),
      endDateError: "",
      isLoading: false,
      newTaskName: "",
      newTaskNameError: "",
      newTaskDetail: "",
      newTaskDetailError: "",
      newTaskComment: "",
      scheduledTime: "12",
      actualTime: "12",
      categoryId: 0,
      tagId: 0,
      requestedId: 0,
      requesterId: 0,
      requesterUserName: "",
      requesterUserNameError: "",
      assign: [
        {
          value: true,
          label: "本番",
        },
        {
          value: false,
          label: "下書き",
        },
      ],
      assignBoolean: true,
      confirmAssign: "",
      numRepro: 1,
    };
  },
//   テスト
  computed: {
    // formCategories() {
    //   return [
    //     {
    //       category_id: 0,
    //       category_name: "全て",
    //     },
    //   ].concat(this.$store.state.categories);
    // },
    formTags() {
      return [
        {
          tag_id: 0,
          tag_name: "全て",
        },
      ].concat(this.$store.state.tags);
    },
    formUserData() {
      return [
        {
          id: -1,
          name: "記入",
        },
      ].concat(this.$store.state.users);
    },
    Reproduction() {
      let range = (start, end) =>
        [...new Array(end - start).keys()].map((n) => n + start);
      return range(1, 21);
    },
  },
  methods: {
    onChangePriority(id) {
      this.priorityId = id;
    },
    onChangeStatus(id) {
      this.statusId = id;
    },
    onCreateName(userName) {
      this.newTaskName = userName;
    },
    onCreateDetail(userDetail) {
      this.newTaskDetail = userDetail;
    },
    onCreateComment(userComment) {
      this.newTaskComment = userComment;
    },
    onChangeStartDate(startDate) {
      this.startDate = startDate;
      this.startDateDialog = false;
    },
    onChangeStart() {
      this.startDate = null;
      this.startDateDialog = false;
    },
    onChangeEndDate(endDate) {
      this.endDate = endDate;
      this.endDateDialog = false;
    },
    onChangeEnd() {
      this.endDate = null;
      this.endDateDialog = false;
    },
    onCreateScheduledTime(scheduledTime) {
      this.scheduledTime = scheduledTime;
    },
    onCreateActualTime(actualTime) {
      this.actualTime = actualTime;
    },
    onChangeCategory(id) {
      this.categoryId = id;
    },
    onChangeTag(id) {
      this.tagId = id;
    },
    onChangeRequested(id) {
      this.requestedId = id;
    },
    onChangeRequester(id) {
      this.requesterId = id;
    },
    onCreateRequesterUser(name) {
      this.requesterUserName = name;
    },
    onChangeAssign(boolean) {
      this.assignBoolean = boolean;
    },
    onChangeRepro(num) {
      this.numRepro = num;
    },
    checkCreateTasks() {
      let isError = false;
      this.newTaskNameError = "";
      this.endDateError = "";
      this.categoryError = "";
      this.requestedUserError = "";
      this.requesterUserNameError = "";
      if (this.newTaskName === "") {
        isError = true;
        this.newTaskNameError = "入力してください";
      }
      if (this.endDate < this.startDate) {
        isError = true;
        this.endDateError = "期限日は開始日よりも前に指定できません";
      }
      if (this.categoryId === 0) {
        isError = true;
        this.categoryError = "選択してください";
      }
      if (this.requestedId === 0) {
        isError = true;
        this.requestedUserError = "選択してください";
      }
      if (this.requesterId === -1 && this.requesterUserName === "") {
        isError = true;
        this.requesterUserNameError = "入力してください";
      }
      // if (this.newTaskDetail === "") {
      //   isError = true;
      //   this.newTaskDetailError = "入力してください";
      // }
      if (isError) return;
      this.createTasks();
    },
    createTasks() {
      if(this.assignBoolean){
        this.confirmAssign = "本番"
      }else{
        this.confirmAssign = "下書き"
      }

      if(this.numRepro !== 1){
        if (confirm(this.numRepro + "件のタスクを" + this.confirmAssign + "に追加しますか？")) {
          for (let i = 0; i < this.numRepro; i++) {
            this.createTask();
          }
        }
      }else{
        this.createTask();
      }
    },
    async createTask() {
      const requestConfig = {
        url: "/task",
        method: "POST",
        data: {
          name: this.newTaskName,
          detail: this.newTaskDetail,
          comment: this.newTaskComment,
          dateStart: this.startDate,
          dateEnd: this.endDate,
          predictedSeconds: this.scheduledTime,
          actualSeconds: this.actualTime,
          categoryId: this.categoryId,
          tagId: this.tagId,
          requestedUserId: this.requestedId,
          requesterUserId: this.requesterId,
          requesterUserName: this.requesterUserName,
          status: this.statusId,
          priority: this.priorityId,
          attribute: this.attribute,
          assignBoolean: this.assignBoolean,
        },
      };
      this.isLoading = true;
      myAxios(requestConfig)
        .then(() => {
          this.$router.push("/main/task");
        })
        .catch((err) => {
          this.newTaskEmailError = err?.response?.data?.errorMessage;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.$store.dispatch("getUsers");
    this.$store.dispatch("getCategories");
    this.$store.dispatch("getTags");
    this.isLoading = false;
  },
};
</script>

<style>
</style>